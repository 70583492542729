import 'unpoly/dist/unpoly'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/alert'
import Splide from '@splidejs/splide';
import Cleave from 'cleave.js';
import Plyr from 'plyr'

require('objectFitPolyfill/dist/objectFitPolyfill.basic.min')
require('cleave.js/dist/addons/cleave-phone.ch');
import hyperform from 'hyperform'

up.compiler('body', (element) => {
    $('html').removeClass('no-js')

    const $body = element;
    let scrollPosition = 0;

    window.disableScroll = () => {
        scrollPosition = window.pageYOffset;
        $body.style.overflow = 'hidden';
        $body.style.position = 'fixed';
        $body.style.top = `-${scrollPosition}px`;
        $body.style.width = '100%';
    };

    window.enableScroll = () => {
        $body.style.removeProperty('overflow');
        $body.style.removeProperty('position');
        $body.style.removeProperty('top');
        $body.style.removeProperty('width');
        window.scrollTo(0, scrollPosition);
    };

    objectFitPolyfill();
})

up.compiler('#js-player', (element) => {
  const player = new Plyr(element, {
    controls: ['play', 'current-time', 'mute', 'volume', 'captions'],
  })

  return () => player.destroy()
})

up.compiler('#js-splide', (element) => {
    const splide = new Splide(element, {
        type: 'fade',
        resetProgress: false,
        pagination: false,
        autoplay: true,
        pauseOnHover: false,
        rewind: true,
        arrows: false
    }).mount({});

    $('.js-slider-next').on('click', () => {
        splide.go('>');
    })

    $('.js-slider-prev').on('click', () => {
        splide.go('<')
    })

    return () => {
        slide.destroy()
        $('.js-slider-next').off('click')
        $('.js-slider-prev').off('click')
    }
})

up.compiler('.js-link-to-clipboard', (element) => {
    $(element).on('click', ($event) => {
        $event.preventDefault();
        let $temp = $("<input>");
        $("body").append($temp);
        $temp.val($(element).attr('href')).select();
        document.execCommand("copy");
        $temp.remove();
    })

    return () => {
        $(element).off('click')
    }
})

up.compiler('.js-phone-field', (element) => {
    new Cleave(element, {
        phone: true,
        phoneRegionCode: 'ch'
    });
})

up.compiler('form', () => {
    hyperform(window);

    hyperform.addTranslation("de", {
        TextTooLong: "Bitte kürzen Sie diesen Text auf maximal %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        TextTooShort: "Bitte verwenden Sie zumindest %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        ValueMissing: "Bitte füllen Sie dieses Feld aus.",
        CheckboxMissing: "Bitte klicken Sie dieses Kästchen an, wenn Sie fortsetzen wollen.",
        RadioMissing: "Bitte wählen Sie eine dieser Optionen.",
        FileMissing: "Bitte wählen Sie eine Datei.",
        SelectMissing: "Bitte wählen Sie einen Eintrag in der Liste.",
        InvalidEmail: "Bitte geben Sie eine E-Mail-Adresse ein.",
        InvalidURL: "Bitte geben Sie eine Internetadresse ein.",
        PatternMismatch: "Bitte halten Sie sich an das vorgegebene Format.",
        PatternMismatchWithTitle: "Bitte halten Sie sich an das vorgegebene Format: %l.",
        NumberRangeOverflow: "Bitte wählen Sie einen Wert, der nicht größer ist als %l.",
        DateRangeOverflow: "Bitte wählen Sie einen Wert, der nicht später ist als %l.",
        TimeRangeOverflow: "Bitte wählen Sie einen Wert, der nicht später ist als %l.",
        NumberRangeUnderflow: "Bitte wählen Sie einen Wert, der nicht kleiner ist als %l.",
        DateRangeUnderflow: "Bitte wählen Sie einen Wert, der nicht früher ist als %l.",
        TimeRangeUnderflow: "Bitte wählen Sie einen Wert, der nicht früher ist als %l.",
        StepMismatch: "Bitte wählen Sie einen gültigen Wert. Die zwei nähesten gültigen Werte sind %l und %l.",
        StepMismatchOneValue: "Bitte wählen Sie einen gültigen Wert. Der näheste gültige Wert ist %l.",
        BadInputNumber: "Bitte geben Sie eine Nummer ein.",
        "Please match the requested type.": "Bitte passen Sie die Eingabe dem geforderten Typ an.",
        "Please comply with all requirements.": "Bitte erfüllen Sie alle Anforderungen.",
        "Please lengthen this text to %l characters or more (you are currently using %l characters).": "Bitte verlängern Sie diesen Text auf mindestens %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        "Please use the appropriate format.": "Bitte verwenden Sie das passende Format.",
        "Please enter a comma separated list of email addresses.": "Bitte geben Sie eine komma-getrennte Liste von E-Mail-Adressen an.",
        "Please select a file of the correct type.": "Bitte wählen Sie eine Datei vom korrekten Typ.",
        "Please select one or more files.": "Bitte wählen Sie eine oder mehrere Dateien.",
        "any value": "jeder Wert",
        "any valid value": "jeder gültige Wert",
    });
    hyperform.setLanguage("de");
})

up.compiler('.js-navbar-toggle', (element) => {
    const $body = $('body')
    const $document = $(document)
    const openMenu = () => {
        $body.addClass('-nav-visible')
        window.disableScroll()
        $document.on('click.hide-menu', (event) => {
            if ($(event.target).closest(".aside").length === 0) {
                closeMenu()
            }
        })
    }
    const closeMenu = () => {
        $body.removeClass('-nav-visible')
        window.enableScroll()
        $document.off('click.hide-menu')
    }

    $(element).on('click', (event) => {
        event.preventDefault()
        event.stopPropagation()

        if ($body.hasClass('-nav-visible')) {
            closeMenu()
        } else {
            openMenu()
        }
    })
})
